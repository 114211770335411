import { render, staticRenderFns } from "./f-form-item.vue?vue&type=template&id=2b19fe0b&"
import script from "./f-form-item.vue?vue&type=script&lang=ts&"
export * from "./f-form-item.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/scalefish/releases/167/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b19fe0b')) {
      api.createRecord('2b19fe0b', component.options)
    } else {
      api.reload('2b19fe0b', component.options)
    }
    module.hot.accept("./f-form-item.vue?vue&type=template&id=2b19fe0b&", function () {
      api.rerender('2b19fe0b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/form/f-form-item.vue"
export default component.exports